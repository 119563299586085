/* eslint-disable max-len */
import { AppImages, variantProvider } from '@/app'
import { View, Image, Text, CenterWrapper, Link, Icon, TextInput, Button } from '@/components'

export type LargeImageProps = {

}

export const LargeImage: React.FC<LargeImageProps> = ({}) => {
  return (
    <Image source={AppImages.HomeAbout} style={styles.image} />
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    image: {
      width: '100%',
      height: 500,
    },
  }),
  true,
)
