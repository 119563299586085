import React from 'react'
import { variantProvider, Settings, Theme, AppImages } from '@/app'
import { useComponentStyle } from '@codeleap/common'

import { View, Image, Text, CenterWrapper, Link, Icon } from '@/components'

export type HomeProps = {}

export const Home: React.FC<HomeProps> = (props) => {
  const backgrounds = [
    { image: AppImages.GreenBackground, message: [{ text: 'Kiss' }, { text: 'Fewer' }, { text: 'Frogs' }] },
    { image: AppImages.RedBackground, message: [{ text: 'Kiss' }, { text: 'Fewer' }, { text: 'Frogs' }] },
    { image: AppImages.OrangeBackground, message: [{ text: 'Kiss' }, { text: 'Fewer' }, { text: 'Frogs' }] },
    { image: AppImages.BlueBackground, message: [{ text: 'Goodbye' }, { text: 'Catfish' }] },
  ]

  const randomBackground = backgrounds[Math.floor(Math.random() * backgrounds.length)]

  const { image, message } = randomBackground

  const styles = useComponentStyle(componentStyles)

  const renderDescription = () => {
    return (
      <View
        css={styles.descriptionWrapper}
        variants={['column']}
      >
        {message?.map(el => <Text text={el?.text} style={{ fontWeight: '900', fontSize: 68 }} variants={['h1', 'white', 'uppercase']} />)}

        <Text
          variants={['h3', 'white', 'semiBold', 'marginVertical:2']}
        >
         Cherry is committed to creating a fun, safe, and honest environment to help as many people as possible find the love of their life or a great companion. Cherry prioritizes its members and values integrity above all.
        </Text>

        <View
          variants={['gap:2', 'marginVertical:2']}
          responsiveVariants={{
            mid: ['alignCenter'],
          }}
        >
          <Link to={'/'}>
            <Icon name={'appStore'} style={styles.linkButton} />
          </Link>
          <Link to={'/'}>
            <Icon name={'googlePlay'} />
          </Link>
        </View>
      </View>
    )
  }

  return (
    <View
      css={[styles.wrapper, { backgroundImage: `url(${image})` }]}
      variants={['fullHeight']}
    >
      <CenterWrapper
        variants={['justifySpaceBetween', 'fullHeight']}
        styles={{
          innerWrapper: styles.centerWrapper,
        }}
      >
        {renderDescription()}
      </CenterWrapper>
    </View>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    minHeight: '100vh',
    width: '100vw',
    maxWidth: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

  },
  image: {},
  linkButton: {
    width: 150,
    height: 50,
  },
  imagePhone: {
    width: '60%',
    maxHeight: '80vh',
    objectFit: 'contain',
    opacity: 1,
    visibility: 'visible',
    [Theme.media.down('mid')]: {
      width: '100%',
    },
    [Theme.media.down('small')]: {
      opacity: 0,
      visibility: 'hidden',
    },
  },
  descriptionWrapper: {
    zIndex: 9,
    width: '40%',
    [Theme.media.down('mid')]: {
      width: '100%',
    },
  },
  centerWrapper: {
    alignItems: 'flex-start',
    [Theme.media.down('mid')]: {
      flexDirection: 'column-reverse',
    },
  },
  boldText: {
    display: 'unset',
    ...Theme.spacing.marginHorizontal(0.8),
  },
}))
