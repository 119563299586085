/* eslint-disable max-len */
import { variantProvider } from '@/app'
import { View, Image, Text, CenterWrapper, Link, Icon, TextInput, Button } from '@/components'

export type SubtitleAndDescriptionProps = {

}

export const SubtitleAndDescription: React.FC<SubtitleAndDescriptionProps> = ({}) => {
  return (
    <CenterWrapper variants={['gap:3', 'paddingVertical:6']}>
      {/* <Image /> */}
      <Text css={styles.title} variants={['h2', 'bold', 'black700']} text={'Cherry is a dating app which focuses on long-term relationship'} />
      <Text css={styles.subtitle} variants={['black700']} text={'Lorem ipsum dolor sit amet consectetur. Sed et integer arcu eros ut. Adipiscing nisl eu odio pellentesque. Orci cras duis feugiat cursus nunc laoreet vel. Felis amet rutrum nunc odio amet sit tellus. Malesuada in nibh et est amet. '} />
    </CenterWrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    title: {
      width: '50%',
    },
    subtitle: {
      width: '50%',
    },
  }),
  true,
)
