/* eslint-disable max-len */
import { variantProvider } from '@/app'
import { View, Image, Text, CenterWrapper, Link, Icon, TextInput, Button } from '@/components'

export type HeadlinesProps = {

}

export const Headlines: React.FC<HeadlinesProps> = ({}) => {

  const renderColumn = () => {
    return (
      <View variants={['column']}>
        <View variants={['gap:2', 'alignCenter']}>
          <Icon name={'heart'} />
          <Text variants={['h2', 'bold', 'black700']} text={'Headline'} />
        </View>
        <Text variants={['black700']} text={'Lorem ipsum dolor sit amet consectetur. Sed et integer arcu eros ut. Adipiscing nisl eu odio pellentesque. Orci cras duis feugiat cursus nunc laoreet vel. Felis amet rutrum nunc odio amet sit tellus. Malesuada in nibh et est amet.'} />
      </View>
    )
  }

  return (
    <CenterWrapper variants={['gap:3', 'paddingVertical:6']}>
      {renderColumn()}
      {renderColumn()}
    </CenterWrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    title: {
      width: '50%',
    },
    subtitle: {
      width: '50%',
    },
  }),
  true,
)
