import { React } from '@/app'
import { Page, View } from '@/components'
import { Compare } from '@/layout'
import { useApiURL } from '@/utils'

const ComparePage: React.FC = () => {
  useApiURL()

  return (
    <Page title='Compare Profiles' center={false} header={false}>
      <View variants={['column']}>
        <Compare />
      </View>
    </Page>
  )
}

export default ComparePage
