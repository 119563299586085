import React, { useState } from 'react'
import { variantProvider, api } from '@/app'
import { Toast } from '@codeleap/web'
import { TCompare, MODULE_NAME, Infos } from '@/types'
import { View, Text, TextInput, Button, Icon } from '@/components'
import { createForm, useForm } from '@codeleap/common'
import Cookies from 'js-cookie'

export const compareProfilesForm = createForm('compare', {
  email: { type: 'text', placeholder: 'email', label: ' ' },
  email_compare: { type: 'text', placeholder: 'compare with', label: ' ' },
  password: { type: 'text', password: true, placeholder: 'password', label: ' ' },
})

export const Compare: React.FC = () => {
  const [compareInfos, setCompareInfos] = useState<TCompare>(null)
  const [loading, setLoading] = useState(false)

  const form = useForm(compareProfilesForm, {
    output: 'json',
    initialState: {
      password: Cookies.get('@Cherry.passCompare'),
      email: Cookies.get('@Cherry.lastEmail'),
      email_compare: Cookies.get('@Cherry.lastEmailCompare'),
    },
    validateOn: 'change',
  })

  const handleSubmit = async () => {
    setLoading(true)
    await api.get(`${MODULE_NAME}owner=${form.values.email}&target=${form.values.email_compare}&password=${form.values.password}`)
      .then(({ data }) => {
        const owner = Object.keys(data)[0]
        const target = Object.keys(data)[1]
        const result = {
          owner: { profile: owner, profileType: 'Owner interest', ...data[owner] },
          target: { profile: target, profileType: 'Target answer', ...data[target] },
        }

        Cookies.set('@Cherry.passCompare', form.values.password)
        Cookies.set('@Cherry.lastEmail', form.values.email)
        Cookies.set('@Cherry.lastEmailCompare', form.values.email_compare)

        setCompareInfos(result)
        setLoading(false)
      }).catch(() => {
        Toast.error({
          title: 'Emails/password invalids',
        })
        setLoading(false)
      })
  }

  const renderForm = () => (
    <View style={styles.form} variants={['column', 'center']}>
      <Text text={`Compare Profiles`} variants={['h3', 'primary', 'marginBottom:2']} />

      <TextInput style={styles.input} {...form.register('email')} />
      <TextInput style={styles.input} {...form.register('email_compare')} />
      <TextInput style={styles.input} {...form.register('password')} />
      <Button
        loading={loading}
        text={'COMPARE'}
        style={styles.compareButton}
        onPress={handleSubmit}
        debugName='Run Compare Profiles'
      />
    </View>
  )

  const renderInfos = (owner: Infos, target?: Infos) => {
    const varsWrapper = ['column', 'wrap', 'justifySpaceBetween', 'alignCenter', 'fullWidth', 'gap:1.5'] as any
    const varsTitle = ['h3', 'bold', 'primary', 'marginTop:5', 'marginBottom:2'] as any
    const varsHeaderText = ['p1', 'marginTop:0.2'] as any

    const renderCategory = (c) => {
      return (
        <Text
          text={`${c?.name}: Total: ${c?._total} - Counter: ${c?._counter}`}
          variants={varsHeaderText}
          style={styles.category}
        />
      )
    }

    const renderHeader = (p, profileCompared) => {
      const isNotShowInSearch = profileCompared?.questions?.filter(q => q?.deal_breaker_triggered === true)?.length >= 1
      return (
        <View style={styles.header} variants={['column', 'gap:2']}>
          <Text text={p.profile} style={styles.email} variants={['bold']} />
          <Text text={`Total Score: ${p.total_score} - Questions: ${p.questions_counter}`} style={styles.email} />
          <Text text={`The profile came up in the search: ${isNotShowInSearch ? 'No' : 'Yes'}`} style={styles.email} variants={['marginBottom:1.5']} />
          {renderCategory(p?.categories?.Personality)}
          {renderCategory(p?.categories?.Interests)}
          {renderCategory(p?.categories?.Values)}
          {renderCategory(p?.categories?.Info)}
        </View>
      )
    }

    const renderUniqueAnswer = (q, profileType) => {
      return (
        <View variants={['row', 'gap:5', 'marginBottom:1']}>
          <View variants={['column', 'gap:0.6']}>
            <Text text='Answers' variants={['p2', 'bold', 'marginTop:1']} style={styles.text} />
            <Text text={`${q?.owner_answer?.option} - ${q?.owner_answer?.text}`} style={styles.text} />
          </View>
          <View variants={['column', 'gap:0.6']}>
            <Text text='Interests' variants={['p2', 'bold', 'marginTop:1']} style={styles.text} />
            <Text text={`${q?.owner_interest?.option} - ${q?.owner_interest?.text}`} style={styles.text} />
          </View>
        </View>
      )
    }

    const renderQuestion = (q, profileType) => {
      if (!q.type) {
        return (
          <View style={styles.question} variants={['column']}>
            <Text text={`${q?.title}`} style={styles.text} variants={['bold']} />
            <View variants={['row', 'gap:5', 'marginBottom:1']}>
              {q?.title === 'distance' ? (
                <>
                  <View variants={['column']}>
                    <Text text='Value' variants={['p2', 'bold', 'marginTop:1']} style={styles.text} />
                    <Text text={`${q?.distance}`} style={styles.text} />
                  </View>
                  <View variants={['column']}>
                    <Text text='Interest' variants={['p2', 'bold', 'marginTop:1']} style={styles.text} />
                    <Text text={`${q?.owner_interest}`} style={styles.text} />
                  </View>
                </>
              ) : (
                <>
                  <View variants={['column']}>
                    <Text text='Answer' variants={['p2', 'bold', 'marginTop:1']} style={styles.text} />
                    {typeof q?.owner_answer === 'string' ? <Text text={`${q?.owner_answer}`} style={styles.text} /> :
                      q?.owner_answer?.map(a => (
                        <Text text={`- ${a?.id}: ${a?.title_question}`} style={styles.text} variants={['marginTop:1']} />
                      ))}
                  </View>
                  <View variants={['column']}>
                    <Text text='Interest' variants={['p2', 'bold', 'marginTop:1']} style={styles.text} />
                    {typeof q?.owner_interest === 'string' ? <Text text={`${q?.owner_interest}`} style={styles.text} /> :
                      q?.owner_interest?.map(a => (
                        <Text text={`- ${a?.id}: ${a?.title_question}`} style={styles.text} variants={['marginTop:1']} />
                      ))}
                  </View>
                </>
              )}
            </View>
            <Text text={`Score: ${q?.score}`} style={styles.text} />
            <Text text={`Deal Breaker Triggered: ${q?.deal_breaker_triggered ? 'yes' : 'no'}`} style={styles.text} />
            <Text text={`Dont Care: ${q?.dont_care ? 'yes' : 'no'}`} style={styles.text} />
          </View>
        )
      }


      return (
        <View style={styles.question} variants={['column']}>
          <Text text={`${q?.id} - ${q?.title}`} style={styles.text} variants={['bold']} />
          {!Array.isArray(q?.owner_interest)
            ? renderUniqueAnswer(q, profileType)
            : (
              <>
                <View variants={['row', 'gap:5', 'marginBottom:1']}>
                  <View variants={['column']}>
                    <Text text='Answers' variants={['p2', 'bold', 'marginTop:1']} style={styles.text} />
                    {q?.owner_answer.map(a => (
                      <Text text={`- ${a?.id}: ${a?.name}`} style={styles.text} variants={['marginTop:1']} />
                    ))}
                  </View>
                  <View variants={['column']}>
                    <Text text='Interests' variants={['p2', 'bold', 'marginTop:1']} style={styles.text} />
                    {q?.owner_interest.map(a => (
                      <Text text={`- ${a?.id}: ${a?.name}`} style={styles.text} variants={['marginTop:1']} />
                    ))}
                  </View>
                </View>
              </>
            )}
          <Text text={`Score: ${q?.score}`} style={styles.text} />
          <Text text={`Category: ${q?.category}`} style={styles.text} />
          <Text text={`Deal Breaker Triggered: ${q?.deal_breaker_triggered ? 'yes' : 'no'}`} style={styles.text} />
          <Text text={`Dont Care: ${q?.dont_care ? 'yes' : 'no'}`} style={styles.text} />
          {q?.owner_interest_weight && <Text text={`Weight: ${q?.owner_interest_weight}`} style={styles.text} />}
        </View>
      )
    }

    const renderGroupQuestion = (q, type = null) => {
      const targetQuestion = target?.questions.filter(q => type === 'bonus' ? !q?.type : q?.type === type).find(targetQ => targetQ.id === q.id)
      return (
        <View variants={['row', 'justifySpaceBetween', 'fullWidth', 'gap:1']}>
          {renderQuestion(q, owner.profileType)}
          {!!targetQuestion && renderQuestion(targetQuestion, target.profileType)}
        </View>
      )
    }

    const renderRestGroupQuestion = (q, profileType) => {
      return (
        <View variants={['row', 'justifySpaceBetween', 'fullWidth', 'gap:1']}>
          {profileType === 'Target answer' ? (
            <>
              <View style={styles.question} variants={['column']}></View>
              {renderQuestion(q, profileType)}
            </>
          ) : (
            <>
              {renderQuestion(q, profileType)}
              <View style={styles.question} variants={['column']}></View>
            </>
          )}
        </View>
      )
    }

    const renderQuestions = (type = null) => {
      const intersection = owner?.questions
        .filter(q => q?.type === type)
        .filter(q => target?.questions.filter(q => q?.type === type).find(e => e.id === q?.id))
        .sort((a, b) => a?.id - b?.id)

      const restOwner = owner?.questions
        .filter(q => q?.type === type)
        .filter(q => {
          const isIntersection = target?.questions.filter(q => q?.type === type).findIndex(v => v.id === q.id) >= 0
          if (isIntersection) {
            return
          } else {
            return q
          }
        })
        .filter(q => {
          const isExistsInIntersection = intersection.findIndex(v => v.id === q.id) >= 0
          if (isExistsInIntersection) {
            return
          } else {
            return q
          }
        })
        .sort((a, b) => a?.id - b?.id)

      const restTarget = target?.questions
        .filter(q => q?.type === type)
        .filter(q => {
          const isIntersection = owner?.questions.filter(q => q?.type === type).findIndex(v => v.id === q.id) >= 0
          if (isIntersection) {
            return
          } else {
            return q
          }
        })
        .filter(q => {
          const isExistsInIntersection = intersection.findIndex(v => v.id === q.id) >= 0
          if (isExistsInIntersection) {
            return
          } else {
            return q
          }
        })
        .sort((a, b) => a?.id - b?.id)

      const allQuestions = { intersection: type === 'bonus' ? owner?.questions.filter(q => !q?.type) : intersection, rest: { owner: [...restOwner], target: [...restTarget] } }

      return (
        <View variants={varsWrapper}>
          {allQuestions.intersection.map(q => renderGroupQuestion(q, type))}
          {allQuestions.rest.owner.map(q => renderRestGroupQuestion(q, owner.profileType))}
          {allQuestions.rest.target.map(q => renderRestGroupQuestion(q, target.profileType))}
        </View>
      )
    }

    return (
      <View variants={['column', 'padding:2', 'fullWidth']}>
        <View variants={['row', 'justifySpaceBetween', 'fullWidth', 'gap:1']}>
          {renderHeader(owner, target)}
          {renderHeader(target, owner)}
        </View>

        <Text text={'Ranked Questions'} variants={varsTitle} />
        {renderQuestions('ranked')}

        <Text text={'Choice Questions'} variants={varsTitle} />
        {renderQuestions('choice')}

        <Text text={'Ordered Questions'} variants={varsTitle} />
        {renderQuestions('ordered')}

        <Text text={'Bonus Questions'} variants={varsTitle} />
        {renderQuestions('bonus')}
      </View>
    )
  }

  return (
    <View style={styles.wrapper} variants={['relative', 'fullHeight', 'justifyCenter']}>
      {!compareInfos
        ? renderForm()
        : (
          <View
            variants={['row', 'alignStart', 'justifySpaceBetween', 'fullWidth']}
            responsiveVariants={{ mid: ['column'] }}
          >
            {renderInfos(compareInfos.owner, compareInfos.target)}
          </View>
        )}

      {!!compareInfos && (
        <Button style={styles.resetButton} onPress={() => setCompareInfos(null)} debugName='Reset compare'>
          <Icon name='close' />
        </Button>
      )}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    minHeight: '100vh',
    width: '100vw',
    maxWidth: '100%',
    backgroundColor: theme.colors.veryLightGray,
  },
  form: {
    width: '100%',
    heigth: '100%',
  },
  header: {
    width: '100%',
    backgroundColor: theme.colors.primary,
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing.value(2),
  },
  text: {
    color: theme.colors.secondary,
    marginBottom: theme.spacing.value(1),
  },
  input: {
    width: 300,
    color: theme.colors.primary,
    padding: theme.spacing.value(1),
  },
  compareButton: {
    width: 320,
    marginTop: theme.spacing.value(1),
    color: theme.colors.veryLightGray,
    borderRadius: theme.borderRadius.small,
    transition: '0.2s',
  },
  resetButton: {
    ...theme.presets.fixed,
    ...theme.presets.center,
    height: 45,
    width: 45,
    bottom: theme.spacing.value(2),
    right: theme.spacing.value(2),
    transition: '0.2s',
    borderRadius: theme.borderRadius.small,
    color: theme.colors.veryLightGray,
    zIndex: 100,
  },
  question: {
    width: '100%',
    padding: theme.spacing.value(1),
    paddingTop: theme.spacing.value(2.5),
    paddingBottom: theme.spacing.value(1.5),
    borderTop: `1px solid ${theme.colors.primary}`,
    ...theme.presets.alignStart,
    ...theme.presets.column,
    gap: 4,
  },
  email: {
    fontSize: theme.typography.baseFontSize,
    color: theme.colors.veryLightGray,
  },
  category: {
    color: theme.colors.veryLightGray,
  },
}), true)
